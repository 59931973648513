import moment from "moment";
import {
  IPayloadProps,
  RowType,
  TAny,
  TForecastTableType,
  TGranularityDateFormat,
  TGranularityDateFormatDisplay,
  TGranularitySelectionType,
  TGranularityType,
  TGranularityTypeDay
} from "app/typings";
import {
  DAILY_DATE_FORMAT,
  DAILY_DISPLAY_FORMAT,
  MONTHLY_DATE_FORMAT,
  MONTHLY_DISPLAY_FORMAT,
  WEEKLY_DATE_FORMAT,
  WEEKLY_DISPLAY_FORMAT
} from "./constants";

import { groupByMonth, groupByWeek } from "../../pages/Settings/Utils/WeekDataConverter";

import LocalStorage from "./LocalStorage";


const collect =
  (pred: TAny) =>
    (xs = []) =>
      xs.flatMap((x: TAny) => [
        ...(pred(x) ? [x] : []),
        ...collect(pred)(x?.children),
      ]);

export const getSelectedKeys = (treeData: TAny) =>
  collect(({ selected }) => selected)(treeData).map(
    (value: TAny) => value.key,
  );

export const getSelectedNode = (treeData: TAny) =>
  collect(({ selected }) => selected)(treeData).map(
    (value: TAny) => value,
  )[0] || {};

export const isObject = (value: TAny): boolean => {
  return value?.constructor === Object;
};

export const convertToSlug = (region: TAny, revert = false) => {
  if (!region) return null;

  if (revert) {
    return region.replaceAll('_', ' ');
  }

  return region.replaceAll(' ', '_');
};

export const sumArrayObjectsValues = (arr = []) => {
  const result = {};

  arr.forEach((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (result[key]) {
        result[key] += value;
      } else {
        result[key] = value;
      }
    });
  });
  return result;
};

export const analyticsQuarters = {
  Q1: ['Jan', 'Feb', 'Mar'],
  Q2: ['Apr', 'May', 'Jun'],
  Q3: ['Jul', 'Aug', 'Sep'],
  Q4: ['Oct', 'Nov', 'Dec'],
};

export const aggregateQuarters = (data: TAny, year: TAny, field = '') => {
  const obj = {} as TAny;
  let currFiscTotal = 0;
  let nextFiscTotal = 0;

  const dataKeys = Object.keys(analyticsQuarters);

  if (!Object.keys(data).length) {
    return {};
  }

  dataKeys.forEach((quarterKey, index) => {
    let currQuarterTotal = 0;
    let nextQuarterTotal = 0;

    analyticsQuarters[quarterKey].forEach((quarter: TAny) => {
      if (data[`${year}-${quarter}`]) {
        currQuarterTotal += data[`${year}-${quarter}`];
      }
      if (data[`${+year + 1}-${quarter}`]) {
        nextQuarterTotal += data[`${+year + 1}-${quarter}`];
      }
    });

    obj[`cfy${quarterKey}`] = currQuarterTotal;
    obj[`nfy${quarterKey}`] = nextQuarterTotal;

    currFiscTotal += currQuarterTotal;
    nextFiscTotal += nextQuarterTotal;

    obj.cfyFY = currFiscTotal;
    obj.nfyFY = nextFiscTotal;
    obj.key = `${field}-${quarterKey}-${index}`;
    obj.field = field === 'Id' ? 'ID' : field || undefined;
  });

  return obj;
};

export const getDaysBetweenDates = (
  dateStart: moment.Moment,
  dateEnd: moment.Moment,
  format = DAILY_DISPLAY_FORMAT,
) => {
  const interim = dateStart.clone();
  const dayIntervals: string[] = [];

  while (dateEnd > interim || interim.format('D') === dateEnd.format('D')) {
    dayIntervals.push(interim.format(format));
    interim.add(1, 'day');
  }
  return dayIntervals;
};

export const getWeeksBetweenDates = (
  dateStart: moment.Moment,
  dateEnd: moment.Moment,
  format = WEEKLY_DISPLAY_FORMAT
) => {
  const result: string[] = [];

  // Adjust start date to the nearest Monday if it's not already a Monday
  const start = dateStart.clone().startOf('isoWeek');

  // Adjust end date to the nearest Sunday if it's not already a Sunday
  const end = dateEnd.clone().endOf('isoWeek');

  const interim = start.clone();

  while (interim.isSameOrBefore(end)) {
    const weekStart = interim.clone();
    const weekEnd = moment.min(interim.clone().endOf('isoWeek'), end);

    result.push(`${weekStart.format(format)}->${weekEnd.format(format)}`);

    interim.add(1, 'week'); // Move to the next week
  }

  return result;
};

export const getMonthsBetweenDates = (
  dateStart: moment.Moment,
  dateEnd: moment.Moment,
  format = MONTHLY_DISPLAY_FORMAT,
) => {
  const interim = dateStart.clone();
  const timeValues: string[] = [];

  while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
    timeValues.push(interim.format(format));
    interim.add(1, 'month');
  }

  return timeValues;
};

export const getDayWeekMonthBetweenDates = (granularity: string, range: string[]) => {
  switch (granularity) {
    case '1': return getDaysBetweenDates(moment(range[0]), moment(range[1]))
    case '2': return getWeeksBetweenDates(moment(range[0]), moment(range[1]))
    case '3': return getMonthsBetweenDates(moment(range[0]), moment(range[1]))
    default: return getDaysBetweenDates(moment(range[0]), moment(range[1]))
  }
}

export const bgColor = (value, record) => {
  if (isObject(value) && value.highlight) {
    return 'lightblue';
  }
  if (value !== undefined && record.key === 'actual highlight') {
    return '#04abd3';
  }
  if (record.key?.includes('bg-gray')) {
    return '#fafafa';
  }
  return '';
};

export const getFetchDataTreePayload = (
  node,
  data: TAny = [],
  hasSelected = false,
) => {
  const { type, name, lineName, regionName } = node;
  const isAllProducts = type === 'all-products';

  return data.map((line: TAny) => {
    const selectedLine =
      isAllProducts || (type === 'line' && line.name === name);

    const regions = line.regions.map((reg: TAny) => {
      const isRegion =
        type === 'region' &&
        line.name === lineName &&
        reg.name === name;

      const selectedRegion = hasSelected
        ? isRegion
        : selectedLine || isRegion;

      const groups = reg.groups.map((group: TAny) => {
        const isGroup =
          type === 'group' &&
          line.name === lineName &&
          reg.name === regionName &&
          group.name === name;

        const selectedGroup = hasSelected
          ? isGroup
          : selectedRegion || isGroup;

        const categories = group.categories.map((category: TAny) => {
          const isCategory =
            type === 'category' &&
            line.name === lineName &&
            reg.name === regionName &&
            category.name === name;

          const selectedCategory = hasSelected
            ? isCategory
            : selectedGroup || isCategory;

          const items = category.items.map((item: TAny) => {
            const isItem =
              type === 'item' &&
              line.name === lineName &&
              reg.name === regionName &&
              item.name === name;

            const selectedItem = hasSelected
              ? isItem
              : selectedCategory || isItem;

            return { ...item, selected: selectedItem };
          });
          return { ...category, selected: selectedCategory, items };
        });
        return { ...group, selected: selectedGroup, categories };
      });
      return { ...reg, selected: selectedRegion, groups };
    });
    return { ...line, selected: selectedLine, regions };
  });
};

export const getGranularityDateFormatDisplay = () => {
  let granularity: TGranularityDateFormatDisplay;
  const index = LocalStorage.getGranularity()
  switch (index) {
    case '1': granularity = DAILY_DISPLAY_FORMAT
      break;
    case '2': granularity = WEEKLY_DISPLAY_FORMAT
      break;
    case '3': granularity = MONTHLY_DISPLAY_FORMAT
      break;
    default: granularity = DAILY_DISPLAY_FORMAT
  }

  return granularity
}

export const sortByDateAfter = (unordered = {}) => {
  return Object.keys(unordered)
    .sort((a, b) =>
      moment(a, getGranularityDateFormatDisplay()).isAfter(moment(b, getGranularityDateFormatDisplay()))
        ? 1
        : -1,
    )
    .reduce((ordered, key) => {
      ordered[key] = unordered[key];
      return ordered;
    }, {});
};

export const getPreviousDay = (format = DAILY_DATE_FORMAT) => {
  return moment()
    .subtract(1, 'day')
    .startOf('day')
    .format(format);
};

export const toUser = (datum) => ({
  key: datum.id,
  info: {
    firstName: datum.first_name,
    lastName: datum.last_name,
    avatarUrl: datum.profile_image,
  },
  role: datum.role,
  email: datum.email,
  isStaff: datum.is_staff,
});

export const staleAndWait = (ms = 1000) => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isEmpty = (obj: object) => obj && Object.keys(obj).length && Object.getPrototypeOf(obj) === Object.prototype;

function formatDate(dateString: string) {
  const months = {
    'Jan': '01',
    'Feb': '02',
    'Mar': '03',
    'Apr': '04',
    'May': '05',
    'Jun': '06',
    'Jul': '07',
    'Aug': '08',
    'Sep': '09',
    'Oct': '10',
    'Nov': '11',
    'Dec': '12'
  };

  const [year, month, day] = dateString.split('-');
  const formattedMonth = months[month];
  return `${year}-${formattedMonth}-${day}`;
}

export const toAdjPayload = (forecast: | Record<string, number> | Record<string, Record<string, string>> | undefined) => {
  if (!forecast) return [];

  return Object.entries(forecast).map(([key, item]) => {
    return ({
      date: formatDate(key),
      value: Number.parseInt(item?.value || item, 10),
      message: item?.message || undefined
    });
  });
};
export class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
  }
}
export const getSelectedGranularity = (index: string) => {
  let granularity: TGranularitySelectionType;
  switch (index) {
    case '1':
      granularity = 'daily'
      break;
    case '2':
      granularity = 'weekly'
      break;
    case '3':
      granularity = 'monthly'
      break;
    default: granularity = 'daily'
  }

  return granularity
}
export const getSelectedGranularityValue = () => {
  let granularity: TGranularitySelectionType;
  const index = LocalStorage.getGranularity()
  switch (index) {
    case '1':
      granularity = 'daily'
      break;
    case '2':
      granularity = 'weekly'
      break;
    case '3':
      granularity = 'monthly'
      break;
    default: granularity = 'daily'
  }

  return granularity
}
export const getGranularity = () => {
  let granularity: TGranularityType;
  const index = LocalStorage.getGranularity()
  switch (index) {
    case '1':
      granularity = 'date'
      break;
    case '2':
      granularity = 'week'
      break;
    case '3':
      granularity = 'month'
      break;
    default: granularity = 'date'
  }

  return granularity
}
export const getGranularityPicker = () => {
  let granularity: TGranularityType;
  const index = LocalStorage.getGranularity()
  switch (index) {
    case '1':
    case '2':
      granularity = 'date'
      break;
    case '3':
      granularity = 'month'
      break;
    default: granularity = 'date'
  }

  return granularity
}
export const getGranularityDay = () => {
  let granularity: TGranularityTypeDay;
  const index = LocalStorage.getGranularity()
  switch (index) {
    case '1':
      granularity = 'day'
      break;
    case '2':
      granularity = 'week'
      break;
    case '3':
      granularity = 'month'
      break;
    default: granularity = 'day'
  }

  return granularity
}
export const getGranularityDateFormat = () => {
  let granularity: TGranularityDateFormat;
  const index = LocalStorage.getGranularity()
  switch (index) {
    case '1':
      granularity = DAILY_DATE_FORMAT
      break;
    case '2':
      granularity = WEEKLY_DATE_FORMAT
      break;
    case '3':
      granularity = MONTHLY_DATE_FORMAT
      break;
    default: granularity = DAILY_DATE_FORMAT
  }

  return granularity
}
export const getColumnRanges = (start: string, end: string) => {
  switch (getGranularity()) {
    case "date": return getDaysBetweenDates(
      moment(start, DAILY_DATE_FORMAT),
      moment(end, DAILY_DATE_FORMAT),
    )
    case "week": return getWeeksBetweenDates(
      moment(start, WEEKLY_DATE_FORMAT),
      moment(end, WEEKLY_DATE_FORMAT),
    )
    case "month": return getMonthsBetweenDates(
      moment(start, MONTHLY_DATE_FORMAT),
      moment(end, MONTHLY_DATE_FORMAT),
    )
    default: return getDaysBetweenDates(
      moment(start, DAILY_DATE_FORMAT),
      moment(end, DAILY_DATE_FORMAT),
    )
  }
}
export const getMadeOnDays = (madeOnStartDay: moment.Moment, madeOnEndDay: moment.Moment) => {
  switch (getGranularity()) {
    case "date":
      return getDaysBetweenDates(madeOnStartDay, madeOnEndDay)
    case "week":
      return getWeeksBetweenDates(madeOnStartDay, madeOnEndDay)
    case "month":
      return getMonthsBetweenDates(madeOnStartDay, madeOnEndDay)
    default:
      return getDaysBetweenDates(madeOnStartDay, madeOnEndDay)
  }
}
export const getActualRow = (actual: TAny) => {
  let data: TAny
  let value: TAny
  switch (getSelectedGranularityValue()) {
    case 'daily': return { key: 'actual highlight', reportTitle: 'Actual', ...actual };
    case 'weekly':
      data = groupByWeek(actual)
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});

      return { key: 'actual highlight', reportTitle: 'Actual', ...value };
    case 'monthly':
      data = groupByMonth(actual)
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});
      return { key: 'actual highlight', reportTitle: 'Actual', ...value };
    default: return { key: 'actual highlight', reportTitle: 'Actual', ...actual };
  }
}
export const getForecastRow = (forecast: object, columnsRange: string[], madeOnDays: string[]) => {
  const forecastRows = [] as RowType[];
  let row: TAny
  let value: TAny
  switch (getSelectedGranularityValue()) {
    case 'daily':
      madeOnDays.forEach((day, index) => {
        row = forecast[day];
        value = row?.[columnsRange[index]];
        if (row && value !== undefined) {
          const madeOnRow = {
            key: `made on ${day}`,
            reportTitle: day,
            // @ts-ignore
            ...row,
            [columnsRange[index]]: { highlight: true, value },
          } as RowType;

          forecastRows.push(madeOnRow);
          delete forecast[day];
        }
      });
      break;
    case 'weekly':
      madeOnDays.forEach((day, index) => {
        const data = groupByWeek(forecast)
        const dataWeek = data.reduce((acc, item) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        }, {});

        row = dataWeek[day]
        value = row?.[columnsRange[index]];
        if (row && value !== undefined) {
          const madeOnRow = {
            key: `made on ${day}`,
            reportTitle: day,
            // @ts-ignore
            ...row,
            [columnsRange[index]]: { highlight: true, value },
          } as RowType;

          forecastRows.push(madeOnRow);
          delete forecast[day];
        }
      });
      break;
    case 'monthly':
      madeOnDays.forEach((day, index) => {
        const weekly = groupByWeek(forecast)
        row = weekly![day];
        value = row?.[columnsRange[index]];
        if (row && value !== undefined) {
          const madeOnRow = {
            key: `made on ${day}`,
            reportTitle: day,
            // @ts-ignore
            ...row,
            [columnsRange[index]]: { highlight: true, value },
          } as RowType;
          forecastRows.push(madeOnRow);
          delete weekly![day];
        }
      });
      break;
    default: madeOnDays.forEach((day, index) => {
      row = forecast[day];
      value = row?.[columnsRange[index]];
      if (row && value !== undefined) {
        const madeOnRow = {
          key: `made on ${day}`,
          reportTitle: day,
          // @ts-ignore
          ...row,
          [columnsRange[index]]: { highlight: true, value },
        } as RowType;
        forecastRows.push(madeOnRow);
        delete forecast[day];
      }
    });
  }

  return forecastRows
}
export const getHistoryRows = (forecast: object) => {
  let historyRows: TAny
  let data: TAny
  let value: TAny

  switch (getSelectedGranularityValue()) {
    case "daily":
      historyRows = Object.keys(forecast)
        .sort((a, b) => moment(a, getGranularityDateFormatDisplay()).isAfter(moment(b, getGranularityDateFormatDisplay())) ? 1 : -1)
        .map((day) => {
          const row = forecast[day];
          return { key: `made on ${day} bg-gray`, reportTitle: day, ...row } as RowType;
        });
      break;
    case "weekly":
      data = groupByWeek(forecast)
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});

      historyRows = Object.keys(value)
        .sort((a, b) => moment(a, getGranularityDateFormatDisplay()).isAfter(moment(b, getGranularityDateFormatDisplay())) ? 1 : -1)
        .map((week) => {
          const row = value[week];
          const dataWeek = groupByWeek(row)
          const valueWeek = dataWeek.reduce((acc, item) => {
            const key = Object.keys(item)[0];
            acc[key] = item[key];
            return acc;
          }, {});

          return { key: `made on ${week} bg-gray`, reportTitle: week, ...valueWeek } as RowType;
        });
      break;
    case "monthly":
      data = groupByMonth(forecast)
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});

      historyRows = Object.keys(value)
        .sort((a, b) => moment(a, getGranularityDateFormatDisplay()).isAfter(moment(b, getGranularityDateFormatDisplay())) ? 1 : -1)
        .map((month) => {
          const row = value[month];
          const dataMonth = groupByMonth(row)
          const valueMonth = dataMonth.reduce((acc, item) => {
            const key = Object.keys(item)[0];
            acc[key] = item[key];
            return acc;
          }, {});

          return { key: `made on ${month} bg-gray`, reportTitle: month, ...valueMonth } as RowType;
        });
      break;
    default:
      historyRows = Object.keys(forecast)
        .sort((a, b) => moment(a, getGranularityDateFormatDisplay()).isAfter(moment(b, getGranularityDateFormatDisplay())) ? 1 : -1)
        .map((day) => {
          const row = forecast[day];
          return { key: `made on ${day} bg-gray`, reportTitle: day, ...row } as RowType;
        });
  }

  return historyRows
}
export const getLeadRows = (leads) => {
  let leadRow
  let data
  let value
  let valueObj

  if (Object.keys(leads).length) {
    switch (getSelectedGranularityValue()) {
      case 'daily':
        leadRow = { key: 'lead highlight', reportTitle: 'Lag', ...leads };
        break;
      case 'weekly':
        data = groupByWeek(leads)
        value = Object.values(data!)

        valueObj = { key: 'lead highlight', reportTitle: 'Lag', value };

        leadRow = {
          key: valueObj.key,
          reportTitle: valueObj.reportTitle,
          ...valueObj.value.reduce((acc, val) => ({ ...acc, ...val }), {})
        };
        break;
      case 'monthly':
        data = groupByWeek(leads)
        value = Object.values(data!)

        valueObj = { key: 'lead highlight', reportTitle: 'Lag', value };

        leadRow = {
          key: valueObj.key,
          reportTitle: valueObj.reportTitle,
          ...valueObj.value.reduce((acc, val) => ({ ...acc, ...val }), {})
        };
        break;
      default: leadRow = { key: 'lead highlight', reportTitle: 'Lag', ...leads };
    }
  } else {
    leadRow = {}
  }
  return leadRow
}
export const getMetricRows = (forecastType: TForecastTableType, horizon: string, lag: number, metrics, columnsRange: string[]) => {
  let metricRows: TAny;
  if (forecastType === 'over_history') {
    const selectedDate = moment(horizon).add(lag - 1, getGranularityDay());
    const lagDay = selectedDate.format(getGranularityDateFormatDisplay());

    metricRows = Object.keys(metrics).map((key) => {
      const value = metrics[key][lagDay];

      return {
        key: `metrics ${key}`,
        reportTitle: key,
        ...metrics[key],
        [lagDay]: { highlight: value !== undefined, value },
      };
    });
  } else {
    switch (getSelectedGranularityValue()) {
      case "daily":
        metricRows = Object.keys(metrics).map((key) => {
          const horizonFirstMonth = columnsRange[0];
          const value = metrics[key][horizonFirstMonth];
          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metrics[key],
            [horizonFirstMonth]: { highlight: value !== undefined, value: metrics[key][horizonFirstMonth] },
          };
        });
        break;
      case "weekly":
        metricRows = Object.keys(metrics).map((key) => {
          const horizonFirstMonth = columnsRange[0];
          const value = metrics[key][horizonFirstMonth];
          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metrics[key],
            [horizonFirstMonth]: { highlight: value !== undefined, value: metrics[key][horizonFirstMonth] },
          };
        });
        break;
      case "monthly":
        metricRows = Object.keys(metrics).map((key) => {
          const horizonFirstMonth = columnsRange[0];
          const value = metrics[key][horizonFirstMonth];
          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metrics[key],
            [horizonFirstMonth]: { highlight: value !== undefined, value: metrics[key][horizonFirstMonth] },
          };
        });
        break;
      default:
        metricRows = Object.keys(metrics).map((key) => {
          const horizonFirstMonth = columnsRange[0];
          const value = metrics[key][horizonFirstMonth];
          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metrics[key],
            [horizonFirstMonth]: { highlight: value !== undefined, value: metrics[key][horizonFirstMonth] },
          };
        });
    }
  }

  return metricRows
}
export const getFvaRow = (key: string, reportTitle: string, inputData: TAny) => {
  let data: TAny
  let value: TAny
  switch (getSelectedGranularityValue()) {
    case 'daily': return { 'key': key, 'reportTitle': reportTitle, ...inputData };
    case 'weekly':
      data = groupByWeek(inputData)
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});

      return { 'key': key, 'reportTitle': reportTitle, ...value };
    case 'monthly':
      data = groupByMonth(inputData)
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});
      return { 'key': key, 'reportTitle': reportTitle, ...value };
    default: return { 'key': key, 'reportTitle': reportTitle, ...inputData };
  }
}


type IProps = {
  date: string,
  value: number
}

/**
 * Distribute data
 * @param value
 * @param count
 */
const eventuallyDivision = (value: number, count: number) => {
  const baseValue = Math.floor(value / count);
  const remainder = value % count;

  const result = Array(count).fill(baseValue);

  for (let i = 0; i < remainder; i += 1) {
    result[i] += 1;
  }

  return result;
}

/**
 * Get week dates
 * @param weekRange
 */
const weekDates = (weekRange: string) => {
  const [from, to] = weekRange.split('->');

  const startOfWeek = new Date(from);
  const endOfWeek = new Date(to);

  const week = [];

  for (let d = startOfWeek; d <= endOfWeek; d.setDate(d.getDate() + 1)) {
    // @ts-ignore
    week.push(moment(d, DAILY_DATE_FORMAT).format(DAILY_DATE_FORMAT));
  }

  return week;
}

const getDaysInMonth = (year: number, month: number) => {
  // Create a Moment object for the first day of the given month and year
  const firstDayOfMonth = moment({ month, year, date: 1 });

  // Get the last day of the month using the `endOf` method
  const lastDayOfMonth = firstDayOfMonth.endOf('month');

  // Calculate the total number of days by subtracting the first day from the last day and adding 1
  return lastDayOfMonth.diff(firstDayOfMonth, 'days') + 1;
};

/**
 * Get month dates
 * @param date
 */
const monthDates = (date) => {
  const year = date.getFullYear();
  const m = date.getMonth();
  const month = m + 1;
  const totalDays = getDaysInMonth(year, month);

  const dates = [];
  for (let i = 1; i <= totalDays; i += 1) {
    // @ts-ignore
    dates.push(moment(`${year}-${month}-${i}`, DAILY_DATE_FORMAT).format(DAILY_DATE_FORMAT));
  }

  return dates;
}

/**
 * Generating data by granularity
 * @param param
 */
export function generateDateValueMap(param: IProps) {
  let dates = [];

  if (getSelectedGranularityValue() === 'daily') {
    return {
      date: param.date,
      value: param.value
    }
  }

  if (getSelectedGranularityValue() === 'weekly') {
    dates = weekDates(param.date);
  } else if (getSelectedGranularityValue() === 'monthly') {
    dates = monthDates(new Date(param.date));
  } else {
    throw new Error('Invalid granularity. should be one of "monthly", "weekly", "daily"');
  }

  const count = dates.length;

  const values = eventuallyDivision(param.value, count);

  return dates.map((date, index) => ({
    date,
    value: values[index]
  }))
}

export const findDuplicates = (data: IPayloadProps[] | undefined) => {
  const seen = {};
  const duplicates: IPayloadProps[] = [];

  data!.forEach(item => {
    const key = `${item.region}-${item.item}`;

    if (seen[key]) {
      duplicates.push(item);
    } else {
      seen[key] = true;
    }
  });

  return duplicates;
};

export const removeDuplicatesWithLastItemKept = (data: IPayloadProps[] | undefined) => {
  const seen = new Set();
  const cleanedData: IPayloadProps[] = [];

  for (let i = data!.length - 1; i >= 0; i-=1) { // Iterate in reverse to keep the last item
    const item = data![i];
    const key = `${item.region}-${item.item}`;

    if (!seen.has(key)) {
      cleanedData.push(item);
      seen.add(key);
    }
  }

  return cleanedData;
};