import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Skeleton } from 'antd';
import { IChartLine } from 'app/typings';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};

interface Props {
  data: IChartLine;
  loading?: boolean;
}

const DemandPlanningChart = ({ data, loading }: Props) => {
  console.log(data)
  if (loading) {
    return <Skeleton />;
  }
  return <Line options={options} data={data} />;
};

DemandPlanningChart.defaultProps = {
  loading: false,
};

export default DemandPlanningChart;
