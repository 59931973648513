import React, { useCallback, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useRecoilState, useRecoilValue } from 'recoil';
import Scaffold from 'app/__portions/Scaffold';
import {
  CapacityState,
  selectedKeysState,
  SelectedNode,
  TreeDataState,
} from 'app/bloc/atoms';
import Api from 'app/Services';
import ENDPOINTS from 'app/Services/endpoints';
import {
  getFetchDataTreePayload,
  getSelectedNode,
} from 'app/utils/helpers';
import { DAILY_DATE_FORMAT } from 'app/utils/helpers/constants';
import CapacityTable from './Table';
import CapacityChart from './Chart';

const { RangePicker } = DatePicker;

const CapacityDemand = () => {
  const [{ dateFilter, hasLoaded }, setCapacityPlanning] = useRecoilState(CapacityState);
  const { treeDataResponse, treeData } =
    useRecoilValue(TreeDataState);
  const [{ capacityKeys }, setSelectedKeys] =
    useRecoilState(selectedKeysState);
  const [{ analyticsKeys: selectedNode }, setSelectedNode] =
    useRecoilState(SelectedNode);

  console.log(dateFilter)

  const fetchCapacity = useCallback(
    async (lines = treeDataResponse, dateRange = dateFilter) => {
      if (!lines.length) return;

      const payload = {
        lines,
        start_date: moment(`${dateRange[0]}-01`).format(
          DAILY_DATE_FORMAT,
        ),
        end_date: moment(`${dateRange[1]}-01`).format(
          DAILY_DATE_FORMAT,
        ),
      };

      setCapacityPlanning((val) => ({ ...val, loading: true }));

      const { data, error } = await Api.post(
        ENDPOINTS.CAPACITY_PLANNING,
        payload,
      );

      if (data) {
        setCapacityPlanning((val) => ({
          ...val,
          capacities: data,
          hasLoaded: true,
          loading: false,
        }));
        return;
      }

      if (error) {
        setCapacityPlanning((val) => ({
          ...val,
          hasLoaded: true,
          loading: false,
        }));
      }
    },
    [dateFilter, setCapacityPlanning, treeDataResponse],
  );

  const onSelect = useCallback(
    async (keys, info) => {
      const lines = getFetchDataTreePayload(
        info.node,
        treeDataResponse,
      ).filter((line) => line.name === 'Cartridges');

      setSelectedNode((nodes) => ({
        ...nodes,
        capacityKeys: info.node,
      }));
      setSelectedKeys((prevKeys) => ({
        ...prevKeys,
        capacityKeys: keys,
      }));

      setCapacityPlanning((val) => ({
        ...val,
        capacities: {
          items: 1,
          days: [],
          months: [],
          weeks: [],
        },
      }));

      await fetchCapacity(lines);
    },
    [
      fetchCapacity,
      setCapacityPlanning,
      setSelectedKeys,
      setSelectedNode,
      treeDataResponse,
    ],
  );

  const onDateChange = useCallback(
    async (_, formatString) => {
      setCapacityPlanning((val) => ({
        ...val,
        dateFilter: formatString,
        capacities: {
          items: 1,
          days: [],
          months: [],
          weeks: [],
        },
      }));
      const lines = getFetchDataTreePayload(
        selectedNode && Object.keys(selectedNode).length === 0
          ? getSelectedNode(treeData)
          : selectedNode,
        treeDataResponse,
      );
      await fetchCapacity(lines, formatString);
    },
    [
      fetchCapacity,
      selectedNode,
      setCapacityPlanning,
      treeData,
      treeDataResponse,
    ],
  );

  useEffect(() => {
    if (!hasLoaded) {
      fetchCapacity();
    }
  }, [fetchCapacity, hasLoaded]);

    return (
    <Scaffold
      // treeData={treeData.map((tree) => ({
      //   ...tree,
      //   children: tree?.children?.filter(
      //     (child) => child.name === 'Cartridges',
      //   ),
      // }))}
      expandedTreeKey="capacityKeys"
      selectedKeys={capacityKeys}
      onSelect={onSelect}
    >
        <div className="ant-row ant-row-space-between ant-space-align-center">
          <h1 className="text-lg letter-28 text-black-50">
          CAPACITY PLANNING
        </h1>
          <RangePicker
              picker="month"
              value={[moment(dateFilter[0]), moment(dateFilter[1])]}
              onChange={onDateChange}
              className="rounded-md border-w-2 btn-height"
          />
      </div>

      <div className="bg-white shadow-sm rounded-md p-5 my-5">
        <CapacityTable />
        <CapacityChart />
      </div>
    </Scaffold>
  );
};

export default CapacityDemand;
