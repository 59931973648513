import React from 'react';
import { Menu } from 'antd';
import { HiOutlineLogout } from 'react-icons/hi';
import { FiUsers, FiSettings } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import RestrictedTo from "../RestrictedTo";
import paths from "../../routes/paths";
import { USER_ROLES } from "../../utils/helpers/constants";

type Props = {
  onLogout?: () => void;
};

const UserActionsOverlay = ({ onLogout }: Props) => {
  const { pathname } = useLocation();
  return (
    <Menu
      className="border rounded-md mt-3"
      style={{ marginTop: '16px' }}
    >

      <RestrictedTo to={[USER_ROLES.Admin.value]} key="users">
        <Menu.Item key="users" className="px-3">
          <Link
            to={paths.USERS_ACTIVITY}
            className={`ant-row ant-space-align-center ant-row-no-wrap text-black hover:text-blue ${
              pathname === paths.USERS_ACTIVITY ? 'border-b-2 border-blue text-blue' : ''
            }`}
          >
            <FiUsers size="16px" />
            <div className="px-1">Users</div>
          </Link>
        </Menu.Item>
      </RestrictedTo>
      <Menu.Item key="settings" className="px-3">
        <div
          key="settings"
          className="ant-row ant-space-align-center ant-row-no-wrap hover:text-blue"
        >
          <Link
            to={paths.SETTINGS}
            className={`ant-row ant-space-align-center ant-row-no-wrap text-black hover:text-blue ${
              pathname === paths.SETTINGS ? 'border-b-2 border-blue text-blue' : ''
            }`}
          >
            <FiSettings size="19.2px" />
            <div className="px-1">Settings</div>
          </Link>
        </div>
      </Menu.Item>
      <Menu.Item key="logout" onClick={onLogout} className="px-3">
        <div
          key="upload"
          className="ant-row ant-space-align-center ant-row-no-wrap hover:text-blue"
        >
          <HiOutlineLogout size="19.2px" />
          <div className="px-1">Logout</div>
        </div>
      </Menu.Item>
    </Menu>
  );
};

UserActionsOverlay.defaultProps = {
  onLogout: () => null,
};

export default UserActionsOverlay;
